import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "~/store";

type NotificationStoreState = {
  diagnosis: { id?: number };
  shouldNotificate: boolean;
  main: any;
};

const initialState: NotificationStoreState = {
  diagnosis: { id: undefined },
  shouldNotificate: false,
  main: {},
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setDiagnosisIdForNotification(
      state,
      action: PayloadAction<
        NotificationStoreState["diagnosis"]["id"] | undefined
      >
    ) {
      if (action.payload === undefined) return;

      let lastItem = state.diagnosis.id;

      if (lastItem !== action.payload) {
        state.diagnosis.id = action.payload;
        state.shouldNotificate = true;
      } else {
        state.shouldNotificate = false;
      }
    },
    setNotificationInfo(
      state,
      action: PayloadAction<NotificationStoreState["main"]>
    ) {
      state.main = action.payload;
    },
  },
});

export const { setDiagnosisIdForNotification, setNotificationInfo } =
  notificationSlice.actions;

export const selectShouldNotificate = (state: RootState) =>
  state.notification.shouldNotificate;
export const selectNotificataionInfo = (state: RootState) =>
  state.notification.main;

export default notificationSlice.reducer;
